export function useBuyCryptoEnabled() {
  const { isEnabled: isEnabledCalypso } = useCalypso()
  const { isEnabledMercuryo } = useMercuryo()

  const isEnabledBuyCrypto = computed(
    () => isEnabledCalypso.value || isEnabledMercuryo.value,
  )

  return { isEnabledBuyCrypto }
}
