import { useSettingsStore } from '@st/core/stores/useSettingsStore'

declare global {
  // eslint-disable-next-line -- why var see in https://stackoverflow.com/questions/59459312/using-globalthis-in-typescript
  var mercuryoWidget: {
    run: (data: {
      host: HTMLElement | null
      widgetId: string
      address: string
      signature: string
      currency: string
      fixCurrency: boolean
      theme: string
    }) => void
  }
}

export function useMercuryo() {
  const { settings } = storeToRefs(useSettingsStore())
  const config = useRuntimeConfig().public.mercuryo

  function initMercuryo({
    address,
    signature,
    widgetId,
    currency,
  }: {
    address: string
    signature: string
    widgetId: string
    currency: string
  }) {
    function runWidget() {
      try {
        mercuryoWidget.run({
          host: document.getElementById(widgetId),
          widgetId: config.widgetId,
          address,
          signature,
          currency,
          fixCurrency: true,
          theme: 'ocp',
        })
      } catch (err) {
        console.log(err)
      }
    }

    if (!window.mercuryoWidget) {
      const script = document.createElement('script')
      script.async = true
      script.src = config.scriptURL

      document.body.appendChild(script)

      script.onload = function () {
        runWidget()
      }
    } else {
      runWidget()
    }
  }

  const isEnabledMercuryo = computed(() => settings.value?.frontMercuryoEnabled)

  return {
    initMercuryo,
    isEnabledMercuryo,
  }
}
